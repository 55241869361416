var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.modals.reminder && _vm.availableReminders.length && _vm.players[_vm.playerIndex])?_c('Modal',{on:{"close":function($event){return _vm.toggleModal('reminder')}}},[_c('h3',[_vm._v("Choose a reminder token:")]),_c('ul',{staticClass:"reminders"},_vm._l((_vm.availableReminders),function(reminder){return _c('li',{key:reminder.role + ' ' + reminder.name,staticClass:"reminder",class:[reminder.role],on:{"click":function($event){return _vm.addReminder(reminder)}}},[_c('span',{staticClass:"icon",style:({
          backgroundImage: `url(${
            reminder.image && _vm.grimoire.isImageOptIn
              ? reminder.image
              : require(
                  '../../assets/icons/' +
                    (reminder.imageAlt || reminder.role) +
                    '.webp',
                )
          })`,
        })}),_c('span',{staticClass:"text"},[_vm._v(_vm._s(reminder.name))])])}),0)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }